import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import Landing from './components/Landing';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

const msalConfig = {
  auth: {
    clientId: '5905a988-fc55-4a78-8e4d-d16cf33159f6',
    authority: 'https://login.microsoftonline.com/b505e40c-a82c-4d4a-9456-e14cf816ce49',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </Router>
    </MsalProvider>
  );
};

export default App;
import React from 'react';
import Logout from './Logout';
import SignIn from './SignIn';

const Header = ({ isAuthenticated }) => (
  <header className="app-header">
    <div className="header-content">
      <div className="home-link">Home</div>
      {isAuthenticated ? <Logout /> : <SignIn />}
    </div>
  </header>
);

export default Header;
import React from 'react';

const Content = ({ isAuthenticated, userName }) => (
  <div className="container">
    <h1 className="welcome-heading">
      {isAuthenticated ? `Welcome, ${userName}! 👋` : 'Welcome to Our Internal Company Website'}
    </h1>
    <p className="info-text">
      {isAuthenticated
        ? 'You are now authenticated and can access company resources.'
        : 'Please sign in using your work email.'}
    </p>
  </div>
);

export default Content;
import React from 'react';
import { useMsal } from '@azure/msal-react';

const SignIn = () => {
  const { instance, accounts } = useMsal();

  const handleSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <div>
      {accounts.length === 0 ? (
        <button onClick={handleSignIn}>Sign In Here</button>
      ) : (
        <p>You are now signed in.</p>
      )}
    </div>
  );
};

export default SignIn;

import React from 'react';
import { useMsal } from '@azure/msal-react';
import Header from './Header';
import Content from './Content';

const Landing = () => {
  const { accounts } = useMsal();
  const user = accounts[0];
  const userClaims = user ? user.idTokenClaims : null;
  const userName = userClaims ? userClaims.given_name || userClaims.name : 'User';

  return (
    <div>
      <Header isAuthenticated={accounts.length > 0} />
      <Content isAuthenticated={accounts.length > 0} userName={userName} />
    </div>
  );
};

export default Landing;